<template>
  <div class="row gap-y">
    <template v-for="(linea, index) in lineas">
      <div class="col-md-4">
        <div class="uikit-example" style="height:100%">
          <div class="card p-2 border shadow-2 hover-shadow-6 card-owework  transition-hover" style="height:100%;">
            <div class="badges text-right" style="position: absolute;right: 10px;top: -10px;">
              <span class="badge badge-transparencia badge-pale font-weight-700 text-transparencia fs-17" style=" padding: 0.45em 0.7em;">{{index + 1}}</span>
            </div>
            <div class="card-body text-center">
              <h6 class="card-title">{{ linea.titulo }}</h6>
              <p class="text-description lh-141 text-left">{{ linea.descripcion }}</p>
              <!-- <a class="fs-13 fw-600 clink-read-more-left" href="#">Leer más <i class="fa fa-angle-right pl-1"></i></a> -->
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "view-lineas",
  data: () => ({
    lineas: [],
  }),
  methods: {
    fetchLineas: async function() {
      await this.$http.get(this.apiRoute("Common", "ListNuestroTrabajoLineaAll")).then(function(response) {
        if (!response.body.error) {
          this.lineas = response.body.data;
        }
      });
    },
  },
  mounted: async function() {
    await this.fetchLineas();
  },
};
</script>
