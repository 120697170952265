<template>
  <section class="container text-transparencia">
    <layout-breadcrumb :items="breadcrumbs"></layout-breadcrumb>
    <div class="row">
      <div class="col-sm-12">
        <h2 class="font-weight-800 text-transparencia">
          <!-- <a @click="$router.go(-1)"
            ><i
              class="fa fa-arrow-left cr-po mr-5"
              data-toggle="tooltip"
              data-placement="top"
              title="Volver"
            ></i
          ></a> -->
          NUESTRO TRABAJO
        </h2>
        <p class="text-transparencia">
          El trabajo de Transparencia, para el período
          <strong>2020-2023</strong>, está ordenado en
          <strong class="text-transparencia">tres</strong> ejes:
        </p>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-sm-12">
        <h4 class="font-weight-600">Líneas de trabajo</h4>
        <!-- <p class="lead">Cards include various options for customizing their backgrounds, borders, and color.</p> -->
        <view-lineas></view-lineas>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-sm-12">
        <h4 class="font-weight-600">Nuestro trabajo</h4>
        <!-- <p class="lead">Cards include various options for customizing their backgrounds, borders, and color.</p> -->
        <view-actividades></view-actividades>
      </div>
    </div>
  </section>
</template>

<script>
import viewLineas from "@/components/nuestroTrabajo/view-lineas";
import viewActividades from "@/components/nuestroTrabajo/view-actividades";
import layoutBreadcrumb from "@/layout/layout-breadcrumb";

export default {
  name: "view-voluntariado",
  metaInfo: {
    title: "Nuestro trabajo",
  },
  data: () => ({}),
  components: {
    viewLineas,
    viewActividades,
    layoutBreadcrumb
  },
  methods: {},
  mounted: function () {
    this.breadcrumbs = [
      { name: "Inicio", url: "/home", icon: "fa fa-home", active: false },
      { name: "Nuestro trabajo", url: "", icon: "", active: true },
    ];
  },
};
</script>
