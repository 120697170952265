<template>
  <div>
    <!-- <div class="slider-dots-line " data-provide="slider" data-slides-to-show="4" data-arrows="true" data-infinite="true" data-autoplay-speed="2500" data-autoplay="true"> -->
    <div class="d-flex justify-content-center flex-wrap">
      <template v-for="(actividad, index) in actividades">
        <div class="col-lg-4 col-sm-6 col-xs-12 mt-3">
          <div class="card border hover-shadow-6 d-block transition-hover card-owework  p-1">
             <h5 class="card-title fs-17 p-2 pt-3 text-center justify-content-center" style="height: 4.5rem;">{{ actividad.titulo }}</h5>
            <div class="card-img-top text-center">
              <img class="cimage-slider mt-2" :src="`${fileUrl(actividad.rutaImagenMiniatura)}`" :alt="actividad.titulo" style="object-fit: contain !important;"/>
            </div>
            <div class="card-body  text-center" style="height:5.1rem !important;overflow:hidden;">
             
              <p class="text-description lh-135 mb-4 fs-16" style="">{{ actividad.sumilla }}</p>
            </div>
            <div class="card-footer text-right pt-2">
              <router-link class="fs-13 fw-600 anim-link-2 anim-link-2-primary" :to="'/nuestrotrabajo/detalle/' + actividad.nuestroTrabajoId"><i class="fa fa-search-plus ml-1"></i> Ver más</router-link>
            </div>
          </div>
        </div>
      </template>
      <!-- </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "view-actividades",
  data: () => ({
    actividades: [],
  }),
  methods: {
    fetchActividades: async function() {
      await this.$http.get(this.apiRoute("Common", "ListNuestroTrabajoAll")).then(function(response) {
        if (!response.body.error) {
          this.actividades = response.body.data;
        }
      });
    },
  },
  mounted: async function() {
    await this.fetchActividades();
  },
};
</script>
